$(document).ready(function () {


  // media queries
  const
    rootStyles = getComputedStyle(document.body),
    mq = [
      window.matchMedia("(max-width: " + rootStyles.getPropertyValue("--mobile-max") + ")"),
      window.matchMedia("(min-width: " + rootStyles.getPropertyValue("--tablet-min") + ") and (max-width: " + rootStyles.getPropertyValue("--tablet-max") + ")"),
      window.matchMedia("(min-width: " + rootStyles.getPropertyValue("--desktop-min") + ")")
    ];


  // resize events
  let current;

  $(window).on("resize", function () {
    // positioning elements
    if (current !== "mobile" && mq[0].matches) {

      $(".js-site-brand-pos").insertAfter($(".js-nav-main-pos"));
      current = "mobile";

    } else if (current !== "tablet" && mq[1].matches) {

      $(".js-site-brand-pos").insertAfter($(".js-hero-text-pos"));
      current = "tablet";

    } else if (current !== "desktop" && mq[2].matches) {

      $(".js-site-brand-pos").insertAfter($(".js-hero-text-pos"));
      current = "desktop";

    }
  }).trigger("resize");


  // drop open and hide when click outside
  const
    links = ".js-dropdown-link",
    innerElements = ".js-dropdown";

  $(document).on("click", links, function (e) {
    if ($(this).hasClass("active")) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass("active");
      $(innerElements).filter(this.hash).removeClass("open");
    } else {
      $(links).removeClass("active").filter('a[href="' + this.hash + '"]').addClass("active");
      $(innerElements).removeClass("open").filter(this.hash).addClass("open");
      $(links).add(innerElements).off();
    }
    e.preventDefault();
  }).on("mouseup touchstart keyup", function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0) || e.keyCode === 27) {
      $(links).removeClass("active");
      $(innerElements).removeClass("open");
    }
  });


  // header amination
  let scroll = $(document).scrollTop();
  let navHeight = $(".js-header").outerHeight(true) || 0;

  $(window).on("scroll", function () {

    let scrolled = $(document).scrollTop();

    if (scrolled > navHeight) {
      $(".js-header").addClass("animate");
    } else {
      $(".js-header").removeClass("animate");
    }

    if (scrolled > scroll) {
      $(".js-header").removeClass("sticky");
    } else {
      $(".js-header").addClass("sticky");
    }

    scroll = $(document).scrollTop();

  });


  // header fixed
  $(window).on("resize", function () {
    $(".js-body").css("padding-top", $(".js-header").outerHeight(true) || 0);
  }).trigger("resize");


  // scroll to
  $(document).on("click", ".js-scroll", function (e) {
    const targetDiv = $(this).attr("href");
    $("html, body").animate({
      scrollTop: $(targetDiv).offset().top
    }, 1000);
    e.preventDefault();
  });


  // scroll to top
  $(document).on("click", ".js-top", function (e) {
    $("html, body").animate({
      scrollTop: 0
    }, 1000);
    e.preventDefault();
  });


  // after scroll
  $(window).bind("scroll", function () {

    let windowHeight = $("body").outerHeight(true) || 0;

    if ($(this).scrollTop() < windowHeight) {
      $("body").removeClass("fixed");
    }
    if ($(this).scrollTop() > windowHeight) {
      $("body").addClass("fixed");
    }
  }).trigger("scroll");


  // custom select
  if ($.fn.select2) {
    $(".js-select").select2({
      minimumResultsForSearch: Infinity
    });
  }


  // floating label
  $('input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]), textarea').each(function () {
    if (this.value) {
      $(this).next(".js-floating-label").addClass('floating-label--init');
    } else {
      $(this).next(".js-floating-label").removeClass('floating-label--init');
    }
  });

  $('input:not([type="checkbox"]):not([type="radio"]):not([type="button"]):not([type="reset"]):not([type="submit"]), textarea').on('change keyup', function () {
    if (this.value) {
      $(this).next(".js-floating-label").addClass('floating-label--placeholder floating-label--init');
    } else {
      $(this).next(".js-floating-label").removeClass('floating-label--placeholder floating-label--init');
    }
  });


  // animation
  AOS.init({
    once: true
  });


});
